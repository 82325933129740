<template>
  <div>
    <v-card flat>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="handleSubmit"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <v-text-field
                v-model="authUserData.username"
                :label="$t('Username')"
                outlined
                dense
                :rules="usernameRules"
                autocomplete="off"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0"
            >
              <v-text-field
                v-model="authUserData.last_name"
                :label="$t('Phone number')"
                outlined
                dense
                :rules="phoneNumberRules"
                autocomplete="off"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0"
            >
              <v-text-field
                v-model="authUserData.email"
                :label="$t('Email')"
                outlined
                dense
                autocomplete="off"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pt-5">
          <v-spacer />

          <v-btn
            type="submit"
            style="width: 100%"
            depressed
            color="primary"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      valid: true,
      usernameRules: [v => !!v || this.$t('Username is required')],
      phoneNumberRules: [v => !!v || this.$t('Phone number is required')]
    }
  },

  computed: {
    ...mapGetters(['authUserData'])
  },

  methods: {
    handleSubmit() {
      if (this.$refs.form.validate()) {
        axios.put(`user/${this.authUserData.id}/`, this.authUserData).then(() => {
          this.$_notify('Updated successfully')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
