<template>
  <div>
    <v-row
      justify="center"
      class="mt-5"
    >
      <v-col
        cols="12"
        sm="7"
        md="5"
        lg="4"
        xl="3"
      >
        <v-card
          flat
          class="datatable-card-background datatable-card-border rounded-lg pt-3 pb-5"
        >
          <v-tabs
            v-model="tab"
            show-arrows
            grow
          >
            <v-tab
              v-for="item in items"
              :key="item"
            >
              {{ $t(item) }}
            </v-tab>
          </v-tabs>

          <v-tabs-items
            v-model="tab"
            class="pt-4"
          >
            <v-tab-item>
              <change-profile />
            </v-tab-item>

            <v-tab-item>
              <change-password />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChangeProfile from './ChangeProfile.vue'
import ChangePassword from './ChangePassword.vue'

export default {
  name: 'Profile',

  components: {
    ChangeProfile,
    ChangePassword
  },

  data() {
    return {
      tab: null,
      items: ['Change Profile', 'Change password']
    }
  }
}
</script>

<style lang="scss" scoped></style>
